// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["p_VStWVkQ"];

const serializationHash = "framer-vh1v7"

const variantClassNames = {p_VStWVkQ: "framer-v-3q5oai"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, g4yXTgLUR: title ?? props.g4yXTgLUR ?? "VIEW PROJECT"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, g4yXTgLUR, mVf_pm_fSCk9U28Qru, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "p_VStWVkQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={mVf_pm_fSCk9U28Qru} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-3q5oai", className, classNames)} framer-1ufa0hm`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"p_VStWVkQ"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-51fbaa80-2371-42f7-947f-8d22e2db73a1, rgb(41, 41, 41))", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy05MDBpdGFsaWM=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "24px", "--framer-font-style": "italic", "--framer-font-weight": "900", "--framer-letter-spacing": "-0.03em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-1aa7a1f9-664a-40d1-a64a-074a2f9865c7, rgb(255, 255, 255)))"}}>VIEW PROJECT</motion.p></React.Fragment>} className={"framer-1j8b48s"} fonts={["GF;Poppins-900italic"]} layoutDependency={layoutDependency} layoutId={"p_VStWVkQHrj8j8S6m"} style={{"--extracted-r6o4lv": "var(--token-1aa7a1f9-664a-40d1-a64a-074a2f9865c7, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={g4yXTgLUR} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vh1v7.framer-1ufa0hm, .framer-vh1v7 .framer-1ufa0hm { display: block; }", ".framer-vh1v7.framer-3q5oai { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 36px; justify-content: center; overflow: hidden; padding: 0px 8px 0px 6px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-vh1v7 .framer-1j8b48s { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vh1v7.framer-3q5oai { gap: 0px; } .framer-vh1v7.framer-3q5oai > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-vh1v7.framer-3q5oai > :first-child { margin-top: 0px; } .framer-vh1v7.framer-3q5oai > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 186
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"g4yXTgLUR":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMMSCSDe0C: React.ComponentType<Props> = withCSS(Component, css, "framer-vh1v7") as typeof Component;
export default FramerMMSCSDe0C;

FramerMMSCSDe0C.displayName = "Cursor";

FramerMMSCSDe0C.defaultProps = {height: 36, width: 186};

addPropertyControls(FramerMMSCSDe0C, {g4yXTgLUR: {defaultValue: "VIEW PROJECT", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerMMSCSDe0C, [{explicitInter: true, fonts: [{family: "Poppins", source: "google", style: "italic", url: "https://fonts.gstatic.com/s/poppins/v21/pxiDyp8kv8JHgFVrJJLm81xlFd2PQEhcqw.woff2", weight: "900"}]}], {supportsExplicitInterCodegen: true})